import React from "react"
import styles from "./imageSection.module.scss"
import Button from "../button/button"


const ImageSection = ({title, shortDescription, description, note, action, actionLabel, amplitude, amplitudeClickEvent, position, bgColor, maxWidth, image, lang}) => {
  const imgUrl = lang === "es" ? `../${image}` : `../../${image}`;
  const setContent = content => ({__html: content});

  const onClickHandler = () => {
    if(amplitude && amplitudeClickEvent) {
      amplitude.getInstance().logEvent(amplitudeClickEvent)
    }

    action()
  };

  return (
    <section className={styles["image-section"]} style={{backgroundColor: `${bgColor}`}}>
      <div className={[styles["image-section_wrapper"], styles[`wrapper-${position || 'left'}`]].join(' ')} style={{maxWidth: maxWidth || ""}}>
        <div className={styles["image-section_wrapper_content"]}>
          {note && <div className={styles["image-section_note"]}>{note}</div>}
          <h2>{title}</h2>
          {shortDescription && <h3>{shortDescription}</h3>}
          {description && <div dangerouslySetInnerHTML={setContent(description)}/>}
          {actionLabel && action && 
            <div>
              <Button text={actionLabel} onClick={onClickHandler}/>
            </div>
          }
        </div>
        {image &&
        <div className={styles["image-section_wrapper_image"]}>
          <img src={imgUrl} alt="text"/>
        </div>
        }
      </div>
    </section>
  )
};

export default ImageSection