import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import ImageSection from "../components/imageSection/imageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const about1 = "<p>We've got in Mexico more than 4 million of them generating 72% of the employment and contributing with up to 52% of the GDP. These are the companies that make Mexico competitive, these are the people that creates an impact and fosters growth within our country.</p>";
const about2 = "<p>However, we know it is not easy. Small companies face myriad problems on a day-to-day basis. 80% of them in Mexico “die” due to inefficient or non-existing financial management, problems managing cash flows, unexpected outgoings, financing options. And the truth is: money anxiety persists in every entrepreneur.</p>";
const about3 = "<p>That's why here at EVVA, we are rethinking business banking by crafting financial experiences made personal, transparent and simple; with the ambition to help the entrepreneurs in Mexico to make better decisions about their money, their business and their future.</p>";

const manifesto1 = "<p><strong>Entrepreneurship:</strong> our daily inspiration. We are focused on the entrepreneur’s financial needs so they can focus on what really matters: their business.</p>";
const manifesto2 = "<p><strong>Veracity:</strong> being transparent and direct with our customers. We are totally against hidden fees and complex pricings.</p>";
const manifesto3 = "<p><strong>Velocity:</strong> everything changes at a lightspeed pace. Being agile and adaptable is key to succeed. We craft agile financial experiences with the ultimate goal of making small businesses grow to the next level.</p>";
const manifesto4 = "<p><strong>Accessible:</strong> being inclusive. Our services must be open to all entrepreneurs that want to create an impact and contribute to its country’s economy.</p>";

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";
const contentMaxWidth = "980px";

const AboutUs = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Thank you for your interest!" subtitle="Access by invitation only, register to request your access." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="about-us" title="About EVVA" description="We are an international team of creators, passionate about innovation in financial technology and its social impact."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="About Us" position="left" image="hero-about.jpg" >
        <AppBar pathname={appState.path} />
      </MainSection>
      <PageSection title="EVVA started with a simple question..." maxWidth={contentMaxWidth}>
        <Card
            title="Why is it so hard for SMEs to get access to financial services?"
            description={`${about1}${about2}${about3}`}
            descriptionColor="#363636"
            padding
        />
      </PageSection>
      <ImageSection
        title="EVVA comes from our manifesto"
        description={`${manifesto1}${manifesto2}${manifesto3}${manifesto4}`}
        position="left"
        image="evva-stats-phone.png"
        maxWidth={contentMaxWidth}
        bgColor="#CEd6E0"
        pathname={appState.lang}
      />
      <PageSection title="Backed by">
        <div style={{margin: "auto", maxWidth: contentMaxWidth}}>
          <img src="../../logo-partners-v2.png" width="100%" alt="ours partners"/>
        </div>
      </PageSection>
      <SecondarySection
        title="Where are we?"
        shortDescription="We are an international team of creators, passionated by financial innovation and its social impact."
        description="<p>Located in Oslo Norway and Mexico City</p>"
        position="center"
        image="evva-offices.jpg"
      />
      {/*
      <PageSection maxColumns="2" title="Our advisors">
        <Card
            subtitle="Yook Shee"
            description="<p>Entrepreneurial leader in banking technology innovation, 20 years of banking experience helping T1 banks and fintech such as BNP Paribas, EVRY and Klarna</p>"
            descriptionColor="#363636"
            image="advisor-1.jpg"
            link="https://www.linkedin.com/in/yookx/"
            linkLabel="LinkedIn profile"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Susanne Hannestad"
            description="<p>Current CEO of Fintech Mundi, helping high potential financial technology companies to develop their ideas, commercialize their products and grow their businesses internationally</p>"
            descriptionColor="#363636"
            image="advisor-2.jpg"
            link="https://www.linkedin.com/in/susannehannestad/"
            linkLabel="LinkedIn profile"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Borghild Holen"
            description="<p>Experienced senior vice president with experience from international banks and international investments. Strong professional skills in international finance and business in emerging markets and developing countries</p>"
            descriptionColor="#363636"
            image="advisor-3.jpg"
            link="https://www.linkedin.com/in/borghild-holen-7256a746/"
            linkLabel="LinkedIn profile"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Magne Angelshaug"
            description="<p>Currently working on new business models and banking innovations in one of the first digital banks in Norway, Sbanken</p>"
            descriptionColor="#363636"
            image="advisor-4.jpg"
            link="https://www.linkedin.com/in/magne-angelshaug-2062041/"
            linkLabel="LinkedIn profile"
            linkIcon="in"
            horizontal
            padding
            shadow
        />
      </PageSection>
        */}
      <SecondarySection
        title="Do you want to know more about us?"
        shortDescription="Let us help you to grow together."
        description="<p>Contact us directly on: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Get Early Access"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default AboutUs